import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import Loading from '../includes/Loading'
import { Link } from 'react-router-dom';
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
const cookies = new Cookies();

export default function Shippingrules() {

    const ShippingrulesPage = (props) => {
        const admin = cookies.get('admin')
        let getToken = cookies.get('token')
        const {AdminBasics, successModal, errorModal, warningModal, formLoading, pagePermissions, assets_url} = CommonFunc()
        const [tab, setTab] = useState("Add")
        const [id, setId] = useState("")
        const [type, setType] = useState("Logistify")
        const [min, setMin] = useState("")
        const [max, setMax] = useState("")
        const [serviceType, setServiceType] = useState("")
        const [loadType, setLoadType] = useState("")
        const [courierName, setCourierName] = useState("")
        const [courierId, setCourierId] = useState("")
        const [loading, setLoading] = useState(false)
        const [shippingRules, setShippingRules] = useState([])

        const addShippingRule = (e) => {
            e.preventDefault();
            formLoading(e.target)
            fetch(apiurl+"api/addShippingRule", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                    type: type,
                    min: min,
                    max: max,
                    serviceType: serviceType,
                    loadType: loadType,
                    courierName: courierName,
                    courierId: courierId,
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1
                })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        successModal("Success", response.message)
                        clear()
                        getShippingRule()
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    
                    $(e.target).find(".loaderbody").remove()
                })
                .catch(err => { });
        }

        
        const editShippingRule = (e) => {
            e.preventDefault();
            formLoading(e.target)
            fetch(apiurl+"api/editShippingRule", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                    id: id,
                    type: type,
                    min: min,
                    max: max,
                    serviceType: serviceType,
                    loadType: loadType,
                    courierName: courierName,
                    courierId: courierId,
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1
                })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        successModal("Success", response.message)
                        clear()
                        setTab("Add")
                        getShippingRule()
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    
                    $(e.target).find(".loaderbody").remove()
                })
                .catch(err => { });
        }


        const clear = () => {
            setType("Logistify")
            setMin("")
            setMax("")
            setServiceType("")
            setLoadType("")
            setCourierName("")
            setCourierId("")
        }

        const getShippingRule = () => {
            if(!loading){
                setLoading(true)
                fetch(apiurl+"api/getShippingRule", {
                    "method": "POST",
                    "headers": {
                        "content-type": "application/json",
                        "accept": "application/json",
                        "Authorization" : "Bearer "+getToken
                    },
                    "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1
                    })
                    })
                    .then(response => response.json())
                    .then(response => {
                        if(typeof response.status !== "undefined" && response.status == "OK"){
                            setShippingRules(response.rules)
                        }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                            if(typeof response.errors !== "undefined"){
                                var i = 0
                                $.each(response.errors, function(index, item){
                                    setTimeout(function(){
                                        errorModal("Oops Something Went Wrong", item[0])
                                    }, i*1000)
                                    i++
                                });
                            }else{
                                errorModal("Oops Something Went Wrong", response.message)
                            }
                        }
                        setLoading(false)
                    })
                    .catch(err => { });
            }
        }

        const removeRule = (item) => {
            if(window.confirm("Are you sure you want to remove?")){
                warningModal("Loading...", "This may take a while.")
                fetch(apiurl+"api/removeShippingRule", {
                    "method": "POST",
                    "headers": {
                        "content-type": "application/json",
                        "accept": "application/json",
                        "Authorization" : "Bearer "+getToken
                    },
                    "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        id: item.id
                    })
                    })
                    .then(response => response.json())
                    .then(response => {
                        if(typeof response.status !== "undefined" && response.status == "OK"){
                            successModal("Success", response.message)
                            getShippingRule()
                        }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                            if(typeof response.errors !== "undefined"){
                                var i = 0
                                $.each(response.errors, function(index, item){
                                    setTimeout(function(){
                                        errorModal("Oops Something Went Wrong", item[0])
                                    }, i*1000)
                                    i++
                                });
                            }else{
                                errorModal("Oops Something Went Wrong", response.message)
                            }
                        }
                    })
                    .catch(err => { });
            }
        }

        const editRule = (item) => {
            setType(item.type)
            setMin(item.min_weight)
            setMax(item.max_weight)
            setServiceType(item.service_type)
            setLoadType(item.load_type)
            setCourierName(item.courier_name)
            setCourierId(item.courier_id)
            setId(item.id)
            setTab("Edit")
        }
        
        useEffect(() => {
            getShippingRule()
        }, [])

        return(
            <>
            {
                pagePermissions(props.pagepermissions, "Shipping_Rules", "add_rule") &&
                tab === "Add" &&
                    <div className='col-md-4'>
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Add New Rule
                                </h3>
                            </div>
                            <form action='' method='POST' onSubmit={(e) => addShippingRule(e)} >
                                <div className='box-body' >
                                    <label>Select Type</label>
                                    <select name='type' className='form-control' value={type} onChange={(e) => setType(e.target.value)} required >
                                        <option value={""}>Select Type</option>
                                        <option value={"DTDC"}>DTDC</option>
                                        <option value={"Logistify"}>Logistify</option>
                                    </select>
                                </div>

                                <div className='box-body'>
                                    <label>Min Weight (KG)<span className='text-red'>*</span></label>
                                    <input type='number' step={0.001} className='form-control' placeholder='e.g 0.1' required value={min} onChange={(e) => setMin(e.target.value)} />
                                </div>
                                <div className='box-body'>
                                    <label>Max Weight (KG)<span className='text-red'>*</span></label>
                                    <input type='number' step={0.001} className='form-control' placeholder='e.g 0.5' required value={max} onChange={(e) => setMax(e.target.value)} />
                                </div>

                                {
                                    type === "DTDC" &&
                                    <>
                                        <div className='box-body'>
                                            <label>Service Type<span className='text-red'>*</span></label>
                                            <select className='form-control' value={serviceType} onChange={(e) => setServiceType(e.target.value)} >
                                                <option value={""} >Select Service Type</option>
                                                <option value={"GROUND EXPRESS"} >GROUND EXPRESS</option>
                                                <option value={"PRIORITY"} >PRIORITY</option>
                                                <option value={"EXPRESS"} >EXPRESS</option>
                                            </select>
                                        </div>
                                        <div className='box-body'>
                                            <label>Load Type<span className='text-red'>*</span></label>
                                            <select className='form-control' value={loadType} onChange={(e) => setLoadType(e.target.value)} >
                                                <option value={""} >Select Load Type</option>
                                                <option value={"NON-DOCUMENT"} >NON-DOCUMENT</option>
                                                <option value={"DOCUMENT"} >DOCUMENT</option>
                                            </select>
                                        </div>
                                    </>
                                }
                                {
                                    type === "Logistify" &&
                                    <>
                                        <div className='box-body'>
                                            <label>Courier Name<span className='text-red'>*</span></label>
                                            <input type='text' className='form-control' placeholder='e.g Bluedart' required value={courierName} onChange={(e) => setCourierName(e.target.value)} />
                                        </div>
                                        <div className='box-body'>
                                            <label>Courier ID<span className='text-red'>*</span></label>
                                            <input type='number' className='form-control' placeholder='e.g 40' required value={courierId} onChange={(e) => setCourierId(e.target.value)} />
                                        </div>
                                    </>
                                }
                                <div className='box-footer'>
                                    <button className='btn btn-primary btn-flat btn-block'>
                                        <i className='fa fa-plus'></i> Add New Rule
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
            }
            {
                pagePermissions(props.pagepermissions, "Shipping_Rules", "edit_rule") &&
                tab === "Edit" &&
                    <div className='col-md-4'>
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Edit Rule
                                </h3>
                                <span className='badge bg-red pull-right' onClick={() => {
                                    clear()
                                    setTab("Add")
                                }} >
                                    <i className='fa fa-times'></i>
                                </span>
                            </div>
                            <form action='' method='POST' onSubmit={(e) => editShippingRule(e)} >
                            <div className='box-body' style={{display:"none"}} >
                                    <label>Select Type</label>
                                    <select name='type' className='form-control' value={type} onChange={(e) => setType(e.target.value)} required >
                                        <option value={""}>Select Type</option>
                                        <option value={"DTDC"}>DTDC</option>
                                        <option value={"Logistify"}>Logistify</option>
                                    </select>
                                </div>

                                <div className='box-body'>
                                    <label>Min Weight (KG)<span className='text-red'>*</span></label>
                                    <input type='number' step={0.01} className='form-control' placeholder='e.g 0.1' required value={min} onChange={(e) => setMin(e.target.value)} />
                                </div>
                                <div className='box-body'>
                                    <label>Max Weight (KG)<span className='text-red'>*</span></label>
                                    <input type='number' step={0.01} className='form-control' placeholder='e.g 0.5' required value={max} onChange={(e) => setMax(e.target.value)} />
                                </div>

                                {
                                    type === "DTDC" &&
                                    <>
                                        <div className='box-body'>
                                            <label>Service Type<span className='text-red'>*</span></label>
                                            <select className='form-control' value={serviceType} onChange={(e) => setServiceType(e.target.value)} >
                                                <option value={""} >Select Service Type</option>
                                                <option value={"GROUND EXPRESS"} >GROUND EXPRESS</option>
                                                <option value={"PRIORITY"} >PRIORITY</option>
                                                <option value={"EXPRESS"} >EXPRESS</option>
                                            </select>
                                        </div>
                                        <div className='box-body'>
                                            <label>Load Type<span className='text-red'>*</span></label>
                                            <select className='form-control' value={loadType} onChange={(e) => setLoadType(e.target.value)} >
                                                <option value={""} >Select Load Type</option>
                                                <option value={"NON-DOCUMENT"} >NON-DOCUMENT</option>
                                                <option value={"DOCUMENT"} >DOCUMENT</option>
                                            </select>
                                        </div>
                                    </>
                                }
                                {
                                    type === "Logistify" &&
                                    <>
                                        <div className='box-body'>
                                            <label>Courier Name<span className='text-red'>*</span></label>
                                            <input type='text' className='form-control' placeholder='e.g Bluedart' required value={courierName} onChange={(e) => setCourierName(e.target.value)} />
                                        </div>
                                        <div className='box-body'>
                                            <label>Courier ID<span className='text-red'>*</span></label>
                                            <input type='number' className='form-control' placeholder='e.g 40' required value={courierId} onChange={(e) => setCourierId(e.target.value)} />
                                        </div>
                                    </>
                                }
                                <div className='box-footer'>
                                    <button className='btn btn-primary btn-flat btn-block'>
                                        <i className='fa fa-save'></i> Update This Rule
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
            }

                <div className='col-md-8'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Shipping Rules List
                            </h3>
                            {
                                loading &&
                                <span className='pull-right label bg-yellow'>Loading...</span>
                            }
                        </div>
                        <div className='box-body'>
                            <table id="bookstable" className="table table-bordered table-hover datatable">
                                <thead>
                                    <tr>
                                        <th style={{width:"80px"}} >S.No</th>
                                        <th>Type</th>
                                        <th>Min Weight</th>
                                        <th>Max Weight</th>
                                        <th>Settings</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        shippingRules.length > 0 &&
                                        shippingRules.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td style={{width:"80px"}} >{index + 1}</td>
                                                    <td>{ item.type }</td>
                                                    <td>{ item.min_weight }</td>
                                                    <td>{ item.max_weight }</td>
                                                    <td>
                                                        {
                                                            item.type === "DTDC" &&
                                                            <>
                                                                {item.service_type} ({item.load_type})
                                                            </>
                                                        }
                                                        {
                                                            item.type === "Logistify" &&
                                                            <>
                                                                {item.courier_name} ({item.courier_id})
                                                            </>
                                                        }
                                                    </td>
                                                    <td>
                                                        <span className='badge bg-yellow' onClick={() => editRule(item)} >
                                                            <i className='fa fa-edit'></i>
                                                        </span>
                                                        &nbsp;
                                                        <span className='badge bg-red' onClick={() => removeRule(item)}>
                                                            <i className='fa fa-trash'></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <Sidebar page={ShippingrulesPage} permission="Shipping_Rules"/>
    )
}
