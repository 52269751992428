import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../includes/Loading';
import base_config from '../../Config';
import Sidebar from '../includes/Sidebar';
import moment from 'moment/moment';
const cookies = new Cookies();

export default function Orders() {
  const OrdersPages = (props) => {
    const colors = { "a" : '#007FFF', "b" : '#0000FF', "c" : '#7B3F00', "d" : '#4B0082', "e" : '#05472A', "f" : '#E25822', "g" : '#FFD700', "h" : '#F0FFF0', "i" : '#4B0082', "j" : '#343434', "k" : '#C3B091', "l" : '#E6E6FA', "m" : '#FF00FF', "n" : '#000080', "o" : '#FFA500', "p" : '#FFE5B4', "q" : '#436B95', "r" : '#FF0000', "s" : '#C0C0C0', "t" : '#D2B48C', "u" : '#120A8F', "v" : '#EE82EE', "w" : '#FFFFFF', "x" : '#738678', "y" : '#FFFF00', "z" : '#0014A8', }

    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const params = useParams()
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, warningModal} = CommonFunc()
    const [refresh, setRefresh] = useState("No")
    const [loading, setLoading] = useState(false)
    const [tab, setTab] = useState(params.type)
    const [orders, setOrders] = useState([])
    const [logs, setLogs] = useState(null)
    const [bulkLoading, setBulkLoading] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    
    
    const [filter, setFilter] = useState({
        "prefixid" : "",
        "payment_id" : "",
        "userdata" : "",
        "information" : "",
        "mode" : [],
        "paid" : "",
        "from" : "",
        "to" : "",
        "gstclaimed" : "",
        "gst_no" : "",
        "service_type" : "",
        "reference_no" : "",
        "promocode_applied" : "",
        "promocode" : "",
        "promocode_link_applied" : "",
        "linkpromocode" : "",
        "books" : "Yes",
        "sortby" : "payments.created_at",
        "sortorder" : "desc"
    })
    const [cancel, setCancel] = useState(null)
    const [segment, setSegment] = useState(0)


    

    const [minStudents, setMinStudents] = useState(1)
    const [maxStudents, setMaxStudents] = useState(10000)
    const [maxStudentsFix, setMaxStudentsFix] = useState(10000)
    
    const [lastPage, setLastPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const getOrders = (currentPage = 1) => {
        setLoading(true)
        var temp = filter
        temp['mode'] = window.$("#channel").val()
        
        fetch(apiurl + "api/orders?page="+currentPage, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    type: tab,
                    filter: temp
                })
            })
            .then(response => response.json())
            .then(response => {
                setOrders(response.orders)
                setLastPage(response.lastpage)


                var max = 10000
                setMaxStudents((max < response.total) ? max : response.total)
                setMaxStudentsFix((max < response.total) ? max : response.total)
                

                setTimeout(function(){
                    // window.$('#studenttable').dataTable()
                    window.$("#channel").unbind().select2()
                }, 500)
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                setLoading(false)
            })
    }
    const changeFilter = (name, value) => {
        var temp = filter
        temp[name] = value
        setFilter(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const changeCancel = (id) => {
        var temp = {
            id : id,
            msg : ""
        }
        setCancel(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const changeReason = (msg) => {
        var temp = cancel
        temp['msg'] = msg
        setCancel(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const cancelCourse = (e) => {
        e.preventDefault()
        formLoading(e.target)
        
        fetch(apiurl + "api/cancelOrder", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({ 
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    cancel: cancel
                })
            })
            .then(response => response.json())
            .then(response => {
                $(e.target).find(".loaderbody").fadeOut()
                setTimeout(function(){  
                    $(e.target).find(".loaderbody").remove()
                }, 1000);
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    setCancel(null)
                    getOrders(1)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
    }

    const markAll = () => {
        if($("#markAll").prop('checked')){
            $('.mark_checkbox:checkbox').prop('checked', true);
        }else{
            $('.mark_checkbox:checkbox').prop('checked', false);
        }
    }

    const [downloading, setDownloading] = useState(false)
    const [downloadingPopup, setDownloadingPopup] = useState(false)
    const downloadRequestFetch = () => {

        const min = parseInt(minStudents)
        const max = parseInt(maxStudents)
        const diff = max - (min-1);
        if(max < min){
            errorModal("Error", '"FROM" Cannot be greater than "TO"')
        }else if(diff > maxStudentsFix){
            errorModal("Error", "Total Users can't be more than 10000")
        }else{
            setDownloading(true)
            
            var temp = filter
            temp['mode'] = window.$("#channel").val()
            
            setDownloadingPopup(false)
            fetch(apiurl+"api/user/getOrdersForDownloadV2", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        courseid: params.id,
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        filter: temp,
                        type: tab,
                        min: min,
                        max: max
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        downloadRequest(response.students, response.th)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                })
                .catch(err => { });
        }

        
    }
    const downloadRequest = (orders, thV2) => {
        setDownloading(true)
        // const th = ["Student ID", "Student Name", "Student Email", "Student Contact", "Course/Package Name", "Course/Package Type", "Order ID", "Payment ID", "Channel", "Date",
        // "Plan Name", "Listing Price", "Selling Price", "Amount Paid", "Coins Used", "Earned CB", "Promocode Discount", "Promocode Used" ]
        const td = orders
        const th = thV2
        


        // var segmentTemp = orders
        // var td = [];

        // for(var j = 0; j < segmentTemp.length; j++){
        //     var temp = orders[j]
        //     for(var i = 0;i<temp.length;i++){

        //         var info = JSON.parse(temp[i]['information'])
        //         var userdata = JSON.parse(temp[i]['userdata'])
        //         // var letter = Array.from(userdata.name)[0];
        //         // var process = JSON.parse(temp[i]['processdata'])
        //         moment.locale('en');
        //         var ct = temp[i]['created_at'];

        //         td.push(
        //             [
        //                 temp[i]['uid'], userdata['name'], userdata['email'], userdata['contact'], info.course.name,
        //                 temp[i]['type'], temp[i]['prefixid'], temp[i]['payment_id'], temp[i]['mode'],  
        //                 moment(ct).format("D-M-Y hh:mm:s A"), info['plan']['plan_name'], info['plan']['listing_price'], info['plan']['selling_price'], 
        //                 temp[i]['paid'], info['price']['deduct'], info['price']['cb'],
        //                 (info['price']['promocode'] !== undefined && info['price']['promocode'] !== null && info['price']['promocode']['promodiscount'] !== undefined) ? info['price']['promocode']['promodiscount'] : 0,
        //                 (info['price']['promocode'] !== undefined && info['price']['promocode'] !== null && info['price']['promocode']['promodiscount'] !== undefined) ? "#" + info['price']['promocode']['code'] : '-',
                        
        //             ]
        //         )
        //     }
        // }
        
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download "+tab+" Orders List of ("+td.length+") Entries."
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }

    const clearFilter = (e) => {
        var temp = filter
        temp = {
            "prefixid" : "",
            "payment_id" : "",
            "userdata" : "",
            "information" : "",
            "mode" : [],
            "paid" : "",
            "from" : filter.from,
            "to" : filter.to,
            "gstclaimed" : "",
            "gst_no" : "",
            "service_type" : "",
            "reference_no" : "",
            "promocode_applied" : "",
            "promocode" : "",
            "promocode_link_applied" : "",
            "linkpromocode" : "",
        }
        setFilter(temp)
        // filterFeedbacks(e)
        setRefresh(refresh === 'No' ? "Yes" : "No")
    }

    const bulkConsignment = () => {
        var check = []
        window.$(".mark_checkbox:checkbox:checked").each(function () {
            check.push($(this).val());
        });
        if(check.length < 1){
            errorModal("Something Went Wrong", "Please Select Atleast 1 Order to add consignment")
        }else if(check.length > 20){
            errorModal("Something Went Wrong", "Max bulk Consignment limit is 20")
        }else{
            setBulkLoading(true)
            warningModal("Loading...", "Saving Consignment Please Wait...")
            
            fetch(apiurl + "api/bulkConsignment", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        orderids: check,
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        successModal("Success", response.message)
                        setLogs(response.logs)
                        setBulkLoading(false)
                        getOrders(1)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    setLoading(false)
                })
        }
    }

    const [bulkAction, setBulkAction] = useState("")
    const [bulkActionReason, setBulkActionReason] = useState("")
    const [bulkActionReasonOther, setBulkActionReasonOther] = useState("")
    
    const [shiptype, setShipType] = useState(false)

    const changeBulkAction = (value) => {
        setBulkAction(value)
    }

    const bulkMove = (e) => {
        e.preventDefault()
        var check = []
        window.$(".mark_checkbox:checkbox:checked").each(function () {
            check.push($(this).val());
        });
        if(check.length < 1){
            warningModal("Oops!!!", "Please select atleast 1 order ")
        }else{
            formLoading(e.target)
            fetch(apiurl + "api/bulkStatusSubmit", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({ 
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        reason: bulkActionReason,
                        other: bulkActionReasonOther,
                        ids: check,
                        process: bulkAction
                    })
                })
                .then(response => response.json())
                .then(response => {

                    $(e.target).find(".loaderbody").remove()

                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        successModal("Success", response.message)
                        setTimeout(function(){
                            window.location.reload()
                        }, 500)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                })
        }
    }


    const bulkProcess = (e) => {
        e.preventDefault()
        var check = []
        window.$(".mark_checkbox:checkbox:checked").each(function () {
            check.push($(this).val());
        });

        if(check.length < 1){
            warningModal("Oops!!!", "Please select atleast 1 order.")
        }
        else if(shiptype === ""){
            warningModal("Oops!!!", "Please select shipping type.")
        }
        else{
            formLoading(e.target)
            setBulkSending(true)
            setSuccessList([])
            setfailedList([])
            setBulkCount(check.length)
            UploadingBulkOrders(e, check)

            
        }
    }

    const [bulkSending, setBulkSending] = useState(false)
    const [bulkSendingCount, setBulkCount] = useState(0)
    
    const [successList, setSuccessList] = useState([])
    const [failedList, setfailedList] = useState([])


    const UploadingBulkOrders = (e, list) => {
        if(list.length > 0){
            
            fetch(apiurl + "api/bulkProcess", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({ 
                        page_url: window.location.href,
                        staff: AdminBasics(),
                        accecible: 1,
                        id: list[0],
                        process: shiptype
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status === "OK"){
                        
                        setSuccessList(successList => [...successList, {orderid: list[0], message: response.message}]);

                        // successModal("Success", response.message)
                        // setTimeout(function(){
                        //     window.location.reload()
                        // }, 500)
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    // errorModal("Oops Something Went Wrong", item[0])
                                    setfailedList(failedList => [...failedList, {orderid: list[0], message: item[0]}]);
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            // errorModal("Oops Something Went Wrong", response.message)
                            setfailedList(failedList => [...failedList, {orderid: list[0], message: response.message}]);
                        }
                    }
                    setTimeout(function(){
                        setBulkCount(bulkSendingCount => bulkSendingCount - 1)
                        list.shift();
                        UploadingBulkOrders(e, list)
                    }, 2000)
                })
        }else{
            $(e.target).find(".loaderbody").remove();
            successModal("Success", "Order Processed Successfully")
        }
    }

    const bulkOptions = {
        unprocessed:[
            "Delivered",
            "Cancel",
            "Failed",
            "Processed",
            "Direct Processed"
        ],
        processed:[
            "Delivered",
            "Cancel",
            "Failed",
            "Place Again",
            "Re-Dispatch"
        ],
        delivered:[
            "Cancel",
            "Failed",
            "Place Again",
            "Re-Dispatch",
            "Processed",
            "Direct Processed"
        ],
        canceled:[
            "Delivered",
            "Failed",
            "Place Again",
            "Re-Dispatch",
            "Processed",
            "Direct Processed"
        ],
        failed:[
            "Delivered",
            "Cancel",
            "Place Again",
            "Re-Dispatch",
            "Processed",
            "Direct Processed"
        ]
    }

    const downloadLabel = (id) => {
        warningModal("Loading...", "This may take a while.")
        fetch(apiurl + "api/downloadLabel", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({ 
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    id: id,
                })
            })
            .then(response => response.json())
            .then(response => {
                
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    window.open(response.url)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }

            })
    }

    var a = 0
    useEffect(() => {
      if(a === 0){
        getOrders(1)
        a++
      }  
    }, [])


    return(
        <>
            
        {
            downloadingPopup &&
            <div className='dialog' id='downloadPopup'>
                <div className='col-md-4'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Export List: <small style={{color:"red"}} >current filters' values will be used</small>
                            </h3>
                            <span className='pull-right cursor-pointer' onClick={() => setDownloadingPopup(false)} >
                                <i className='fa fa-times'></i>
                            </span>
                        </div>
                        <div className='box-body'>
                            <label>From</label>
                            <input type='number' className='form-control' placeholder='e.g 1' value={minStudents} onChange={(e) => setMinStudents(e.target.value)} />
                        </div>
                        <div className='box-body'>
                            <label>To</label>
                            <input type='number' className='form-control' placeholder='e.g 100' value={maxStudents} onChange={(e) => setMaxStudents(e.target.value)} />
                        </div>
                        <p style={{color:"red", paddingLeft:10}}>
                            <strong>
                                Total Users can't be more than {maxStudentsFix}
                            </strong>
                        </p>
                        <div className='box-footer'>
                            <button className='btn btn-primary btn-flat btn-block' onClick={() => downloadRequestFetch()} >Download Students</button>
                        </div>
                    </div>
                </div>
            </div>
    }
        {
            logs !== null &&
            <>
            {
                logs.success.length > 0 &&
                <div className='col-md-6'>
                    <div className="alert alert-success alert-dismissible">
                        <button type="button" className="close" onClick={() => setLogs(null)} data-dismiss="alert" aria-hidden="true">×</button>
                        <h4><i className="icon fa fa-check"></i> Consignment Successful!</h4>
                        <ul>
                            {
                                logs.success.map((item, index) => {
                                    return(
                                        <li key={index+"log"} dangerouslySetInnerHTML={{__html: item.message}} ></li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            }
            {
                logs.errors.length > 0 &&
                <div className='col-md-6'>
                    <div className="alert alert-danger alert-dismissible">
                        <button type="button" className="close" onClick={() => setLogs(null)} data-dismiss="alert" aria-hidden="true">×</button>
                        <h4><i className="icon fa fa-ban"></i> Consignment Failed!</h4>
                        <ul>
                            {
                                logs.errors.map((item, index) => {
                                    return(
                                        <li key={index+"logs"} dangerouslySetInnerHTML={{__html: item.message}} ></li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            }
            </>
        }
        {
            showFilter &&
        <div className='col-md-12'>
            
        <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        Filter Order(s)
                    </h3>
                    <button className='btn btn-danger btn-flat pull-right' onClick={() => setShowFilter(false)} >
                        <i className='fa fa-times'></i>
                    </button>
                        <button className='btn btn-warning btn-flat pull-right' onClick={(e) => clearFilter(e)} style={{marginRight:"10px"}} >
                            Clear Filter
                        </button>
                </div>
                {/* <form action='' method='POST'> */}
                    <div className='box-body'>
                        <div className='col-md-3 no-left-padding'>
                            <label>
                                Order ID
                            </label>
                            <input type={"text"} className="form-control" placeholder='e.g COURSE1669028852' value={filter.prefixid} onChange={(e) => changeFilter("prefixid", e.target.value)} />
                        </div>
                        <div className='col-md-3'>
                            <label>
                                Payment ID
                            </label>
                            <input type={"text"} className="form-control" placeholder='e.g MOJO2b21L05A80543294' value={filter.payment_id} onChange={(e) => changeFilter("payment_id", e.target.value)}  />
                        </div>
                        <div className='col-md-3 '>
                            <label>
                                Student (Name, Email, Contact)
                            </label>
                            <input type={"text"} className="form-control" placeholder='e.g info@example.com' value={filter.userdata} onChange={(e) => changeFilter("userdata", e.target.value)}  /> 
                        </div>
                        <div className='col-md-3 no-right-padding'>
                            <label>
                                Course/Package
                            </label>
                            <input type={"text"} className="form-control" placeholder='e.g CA Foundation' value={filter.information} onChange={(e) => changeFilter("information", e.target.value)}  />
                        </div>
                    </div>
                    <div className='box-body' style={{display:"flex", alignItems:"flex-end"}}>
                        
                        <div className='col-md-3 no-left-padding'>
                            <label>
                                Channel
                            </label>
                            <select className='form-control' multiple id='channel' value={filter.mode} onChange={(e) => changeFilter("mode", e.target.value)} data-placeholder='Select Channel' >
                                <option value={"Web"}>Web</option>
                                <option value={"Android"}>Android</option>
                                <option value={"Ios"}>Ios</option>
                                <option value={"Admin"}>By Admin</option>
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <label>
                                Paid/Free
                            </label>
                            <select className='form-control' value={filter.paid} onChange={(e) => changeFilter("paid", e.target.value)}  >
                                <option value={""}>Any</option>
                                <option value={"Paid"}>Paid</option>
                                <option value={"Free"}>Free</option>
                            </select>
                        </div>
                        <div className='col-md-3 '>
                            <label>
                                From
                            </label>
                            <input type={"date"} className="form-control" value={filter.from} onChange={(e) => changeFilter("from", e.target.value)}  /> 
                        </div>
                        <div className='col-md-3 no-right-padding'>
                            <label>
                                To
                            </label>
                            <input type={"date"} className="form-control" value={filter.to} onChange={(e) => changeFilter("to", e.target.value)}  /> 
                        </div>
                    </div>
                    <div className='box-body' style={{display:"flex", alignItems:"flex-end"}}>
                        
                        <div className='col-md-3 no-left-padding'>
                            <label>
                                GST Claimed
                            </label>
                            <select className='form-control' value={filter.gstclaimed} onChange={(e) => changeFilter("gstclaimed", e.target.value)}  >
                                <option value={""}>Any</option>
                                <option value={"Yes"}>Yes</option>
                                <option value={"No"}>No</option>
                            </select>
                        </div>
                        <div className='col-md-3 '>
                            <label>
                                GST No
                            </label>
                            <input type={"text"} className="form-control" value={filter.gst_no} onChange={(e) => changeFilter("gst_no", e.target.value)} placeholder={"e.g 12345678912345"}  /> 
                        </div>
                        <div className='col-md-3'>
                            <label>
                                Service Type
                            </label>
                            <select className='form-control' value={filter.service_type} onChange={(e) => changeFilter("service_type", e.target.value)}  >
                                <option value={""}>Any</option>
                                <option value={"GROUND EXPRESS"} >GROUND EXPRESS</option>
                                <option value={"PRIORITY"} >PRIORITY</option>
                            </select>
                        </div>
                        <div className='col-md-3 no-right-padding'>
                            <label>
                               AWB No
                            </label>
                            <input type={"text"} className="form-control" value={filter.reference_no} onChange={(e) => changeFilter("reference_no", e.target.value)} placeholder={"e.g D77346338"} /> 
                        </div>
                    </div>
                    
                    <div className='box-body' style={{display:"flex", alignItems:"flex-end"}}>
                        
                        <div className='col-md-3 no-left-padding'>
                            <label>
                                Promocode Applied?
                            </label>
                            <select className='form-control' value={filter.promocode_applied} onChange={(e) => changeFilter("promocode_applied", e.target.value)}  >
                                <option value={""}>Any</option>
                                <option value={"Yes"}>Yes</option>
                                <option value={"No"}>No</option>
                            </select>
                        </div>
                        <div className='col-md-3 '>
                            <label>
                                Promocode
                            </label>
                            <input type={"text"} className="form-control" value={filter.promocode} onChange={(e) => changeFilter("promocode", e.target.value)} placeholder={"e.g WELCOME100"}  /> 
                        </div>
                        <div className='col-md-3'>
                            <label>
                                Promocode Link Applied
                            </label>
                            <select className='form-control' value={filter.promocode_link_applied} onChange={(e) => changeFilter("promocode_link_applied", e.target.value)}  >
                                <option value={""}>Any</option>
                                <option value={"Yes"} >Yes</option>
                                <option value={"No"} >No</option>
                            </select>
                        </div>
                        <div className='col-md-3 no-right-padding'>
                            <label>
                                Link Promocode
                            </label>
                            <input type={"text"} className="form-control" value={filter.linkpromocode} onChange={(e) => changeFilter("linkpromocode", e.target.value)} placeholder={"e.g D77346338"} /> 
                        </div>
                    </div>

                    <div className='box-body' style={{display:"flex", alignItems:"flex-end"}}>
                        
                    <div className='col-md-3 no-left-padding'>
                            <label>
                                Sort By
                            </label>
                            <select className='form-control' value={filter.sortby} onChange={(e) => changeFilter("sortby", e.target.value)}  >
                                <option value={"courses.name"}>Course/Package Name</option>
                                <option value={"payments.created_at"}>Order Date</option>
                            </select>
                        </div>
                        <div className='col-md-3'>
                            <label>
                                Sort Order
                            </label>
                            <select className='form-control' value={filter.sortorder} onChange={(e) => changeFilter("sortorder", e.target.value)}  >
                                <option value={"asc"}>A-Z</option>
                                <option value={"desc"}>Z-A</option>
                            </select>
                        </div>
                    </div>

                    <div className='box-footer'>
                        <input type={"submit"} value={"Search"} className="btn btn-primary btn-flat pull-right btn-block" onClick={() => getOrders(1)} />
                    </div>
                {/* </form> */}
            </div>
        </div>
        }
        {
            loading ?
        <div className='col-md-12'>
            <div className='box no-border'>
                <div className='box-body' style={{paddingTop:"100px", paddingBottom: "100px"}}>
                    <Loading />
                </div>
            </div>
        </div>
    :
    <>
        <div className={ (cancel === null && bulkAction === "") ? "col-md-12" : "col-md-8"}>
            <div className="nav-tabs-custom">
            <ul className="nav nav-tabs">
                <li className={ (tab === 'unprocessed') ? "active" : ''}><Link to={"/admin/orders/unprocessed"} title={"Un-Processed Orders"} >Un-Processed Orders</Link></li>
                <li className={ (tab === 'processed') ? "active" : ''}><Link to={"/admin/orders/processed"} title={"Processed Orders"}>Processed Orders</Link></li>
                <li className={ (tab === 'delivered') ? "active" : ''}><Link to={"/admin/orders/delivered"} title={"Delivered Orders"}>Delivered Orders</Link></li>
                <li className={ (tab === 'canceled') ? "active" : ''}><Link to={"/admin/orders/canceled"} title={"Canceled Orders"}>Canceled Orders</Link></li>
                <li className={ (tab === 'failed') ? "active" : ''}><Link to={"/admin/orders/failed"} title={"Failed Orders"}>Failed Orders</Link></li>
                
                {
                    !showFilter &&
                    <li className='pull-right'>
                        <button className='btn btn-success btn-flat' onClick={() => {
                            setShowFilter(true)
                            setTimeout(function(){
                                // window.$('#studenttable').dataTable()
                                window.$("#channel").unbind().select2()
                            }, 500)
                            }} >
                            <i className='fa fa-filter'></i>
                        </button>
                    </li>
                }
                <li className='pull-right'>
                    {
                        !downloading ?
                        <button className='btn btn-primary btn-flat pull-right' style={{marginRight:"10px"}} onClick={() => setDownloadingPopup(true)} >
                            Download
                        </button>
                        :
                        <button className='btn btn-warning btn-flat pull-right' >
                            Preparing...
                        </button>
                    }
                </li>
                
                {
                    // tab !== 'unprocessed' &&
                    <li className='pull-right'>
                        <select name='' className='form-control' value={bulkAction} onChange={(e) => changeBulkAction(e.target.value)} >
                            <option value={""} >Bulk Option</option>
                            {
                                bulkOptions[tab] !== undefined &&
                                bulkOptions[tab].map((item, index) => {
                                    return(
                                        <option key={"bulk"+index} value={item} >{item}</option>
                                    )
                                })
                            }
                        </select>
                    </li>
                }
                {
                //     (tab === 'unprocessed' || tab === "failed") && 
                // <li className='pull-right'>
                //     {
                //         <button className='btn btn-danger btn-flat pull-right' onClick={() => bulkConsignment()} >
                //             Send Bulk Consignment?
                //         </button>
                //     }
                // </li>
                }
            </ul>
            <div className="tab-content">
                <div className="tab-pane active" id="tab_1" style={{backgroundColor:"#FFFFFF", overflow:"auto", position:"relative"}} >

                    {
                        bulkLoading &&
                        <div style={{width:"100%", height:"100%", position:"absolute", top:0, left:0, backgroundColor:"rgba(255,255,255,0.5)"}}>
                            <Loading />
                        </div>
                    }
                    <table id="studenttable" className="table table-bordered table-hover datatable">
                        <thead>
                            <tr>
                                <th >
                                    <input type={"checkbox"} id={"markAll"} onChange={() => markAll()}/>
                                </th>
                                <th style={{width:"40px"}} >S.No</th>
                                <th>Student</th>
                                {
                                    cancel === null &&
                                    <th>Contact</th>
                                }
                                <th>Course/Package</th>
                                <th>Order ID</th>
                                <th>Payment ID</th>
                                {
                                    (tab !== 'unprocessed') &&
                                    <th>AWB No</th>
                                }
                                {
                                    cancel === null &&
                                    <>
                                        <th>Type</th>
                                        <th>Channel</th>
                                        <th>Amount</th>
                                        <th className='text-center' >Date</th>
                                    </>
                                }
                                {
                                    (tab === 'canceled') &&
                                    <th>Reason</th>
                                }
                                <th className='text-right' >Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                typeof orders[segment] !== "undefined" &&
                                orders[segment].length > 0 &&
                                orders[segment].map((item, index) => {
                                    var info = JSON.parse(item.information)
                                    var userdata = JSON.parse(item.userdata)
                                    var letter = Array.from(userdata.name)[0];
                                    var process = JSON.parse(item.processdata)
                                    var staff = (item.staff === null) ? null : JSON.parse(item.staff)
                                    if(!pagePermissions(props.pagepermissions, "course"+item.course_id, "show_order_details")){
                                        userdata['name'] = (userdata['name'].substring(0,3)) + '*'.repeat((userdata['name'].length > 3) ? userdata['name'].length - 3 : 1) 
                                        userdata['contact'] = (userdata['contact'].substring(0,4)) + "******"
                                        var expEmail = userdata['email'].split("@")
                                        userdata['email'] = (expEmail[0].substring(0,3)) + '*'.repeat((expEmail[0].length > 3) ? expEmail[0].length - 3 : 1) + "@" + ((expEmail[1] !== undefined) ? expEmail[1] : "")
                                    }
                                    moment.locale('en'); 
                                    var consignment = (item.consignment === null) ? null : JSON.parse(item.consignment)
                                    
                                    var ct = item.created_at;
                                    var userid = item.uid.replace("TEMP", "");
                                    var shippedDate = new Date(item.shippeddate * 1000);
                                    return(
                                        <tr key={"orders"+index} className={(cancel !== null && cancel.id === item.prefixid) ? 'background-red' : ''}>
                                            <td>
                                                {/* <input type={"checkbox"} name="mark[]" className='mark_checkbox' value={item.purchase_id + "****" + userid}/> */}
                                                <input type={"checkbox"} name="mark[]" className='mark_checkbox' value={item.prefixid}/>
                                            </td>
                                            <td  >{(index+1)}</td>
                                            <td onClick={() => (pagePermissions(props.pagepermissions, "course"+item.course_id, "show_order_details")) ? window.open('/admin/student-profile/'+userid) : ''}>
                                                <div className='studentName'>
                                                    <div className='profilePic' style={{color:colors[letter.toLowerCase()], backgroundColor: colors[letter.toLowerCase()]+"29"}}>
                                                        {letter.toUpperCase()}
                                                    </div>
                                                    <span style={{whiteSpace:"nowrap"}} >
                                                        <strong>{userdata.name}</strong><br />
                                                    </span>
                                                </div>
                                            </td>
                                            {
                                                cancel === null &&
                                            <td style={{color:"#3c8dbc"}} >
                                                <strong style={{whiteSpace:"nowrap"}} className="cursor-pointer" title={'Send Mail On '+userdata.email} onClick={() => window.location.href='mailto:'+userdata.email} > 
                                                    <i className='fa fa-envelope'></i> {userdata.email}
                                                </strong><br />
                                                <strong style={{whiteSpace:"nowrap"}} className="cursor-pointer" title={'Make Call On '+userdata.contact} onClick={() => window.location.href='tel:'+userdata.contact} ><i className='fa fa-phone'></i> {userdata.contact}</strong><br />
                                            </td>
                                            }
                                            <td>
                                                {/* <Link to={"/admin/course/" + item.course_id } style={{color: (item.needhelp !== null) ? "red" : "" }}> */}
                                                <div onClick={() => {
                                                    changeFilter("information", info.course.name)
                                                    setTimeout(function(){
                                                        getOrders(1);
                                                    }, 500)
                                                }}>
                                                    <strong style={{color:"#3c8dbc", cursor:"pointer"}}>
                                                        {info.course.name} 
                                                        
                                                        {
                                                            info.course.books !== null &&
                                                            <i className='fa fa-book'></i>
                                                        }
                                                        {
                                                            item.redispatched === 1 &&
                                                            <> &nbsp;
                                                                <i className='fa fa-refresh'></i>
                                                            </>
                                                        }
                                                        {
                                                            item.needhelp !== null &&
                                                            <>
                                                            <span style={{color:"red"}} >
                                                            &nbsp;
                                                            <i className='fa fa-info-circle'></i>
                                                            &nbsp;
                                                            </span>
                                                            </>
                                                        }
                                                        {
                                                            info.price.deduct !== undefined && info.price.deduct > 0 &&
                                                            <span style={{color:"#EFCD5A"}} >
                                                            &nbsp;
                                                            <i className='fa fa-database' ></i>
                                                            </span>
                                                        }
                                                        <br />
                                                        {
                                                            item.fail_reason!== undefined &&
                                                            <small dangerouslySetInnerHTML={{__html: item.fail_reason}} style={{color:"red"}}></small>
                                                        }
                                                        
                                                        {
                                                            item.bulkreason !== null &&
                                                            <>
                                                                <br />
                                                                <small dangerouslySetInnerHTML={{__html: '{'+item.bulkreason+'}'}} style={{color:"red"}}></small>
                                                            </>
                                                        }
                                                    </strong>
                                                </div>
                                                {/* </Link> */}
                                            </td>
                                            <td>
                                                <Link to={"/admin/order-info/"+item.purchase_id + "/" + userid} title={"View Order Information"} target={"_blank"} >
                                                    <strong>
                                                        {item.prefixid}
                                                    </strong>
                                                </Link>
                                            </td>
                                            <td>{item.payment_id}</td>
                                            {
                                                (tab !== 'unprocessed') &&
                                                <td>
                                                    {item.reference}
                                                    {
                                                        consignment !== null &&
                                                        <>
                                                            <br />
                                                            <small>
                                                                {consignment.shipped_via} ({(consignment.shipped_company !== undefined) ? consignment.shipped_company : ""})
                                                            </small>
                                                        </>
                                                    }
                                                </td>
                                            }
                                            {
                                                cancel === null &&
                                                <>
                                                    <td>
                                                        {item.type}
                                                    </td>
                                                    <td>
                                                        {
                                                            item.mode === "Admin" && staff !== null ?
                                                            <Link to={'/admin/staff?id='+staff.id} target='_blank' >{item.mode}</Link>
                                                            :
                                                            item.mode
                                                        }
                                                    </td>
                                                    
                                                    <td>
                                                        {
                                                            info.plan.plan_name !== undefined &&
                                                            <>
                                                                <small style={{whiteSpace:"nowrap"}} >Plan Name: {info.plan.plan_name}</small><br />
                                                            </>
                                                        }
                                                        {
                                                            info.plan.listing_price > 0 &&
                                                            <>
                                                                <small style={{whiteSpace:"nowrap"}} >Listing Price: {info.plan.listing_price}</small><br />
                                                            </>
                                                        }
                                                        {
                                                            info.plan.selling_price > 0 &&
                                                            <>
                                                                <small style={{whiteSpace:"nowrap"}} >Selling Price: {info.plan.selling_price}</small><br />
                                                            </>
                                                        }
                                                        {
                                                            item.paid > 0 &&
                                                            <>
                                                                <small style={{whiteSpace:"nowrap"}} >Amount Paid: {(item.paid === "0" || item.paid === 0) ? 'Free' : item.paid}</small><br />
                                                            </>
                                                        }
                                                        {
                                                            info.price.deduct > 0 &&
                                                            <>
                                                                <small style={{whiteSpace:"nowrap"}} >Coins Used: {info.price.deduct}</small><br />
                                                            </>
                                                        }
                                                        {
                                                            info.price.cb > 0 &&
                                                            <>
                                                                <small style={{whiteSpace:"nowrap"}} >Earned CB: {info.price.cb}</small><br />
                                                            </>
                                                        }
                                                        {
                                                             info.price.promocode !== undefined && info.price.promocode !== null && info.price.promocode.promodiscount > 0 &&
                                                            <>
                                                                <small style={{whiteSpace:"nowrap"}} >Promocode Discount: {info.price.promocode.promodiscount} (#{info.price.promocode.code})</small><br />
                                                            </>
                                                        }
                                                    </td>
                                                    <td style={{whiteSpace:"nowrap", textAlign:"center"}}>
                                                        {moment(ct).format("D-M-Y hh:mm:s A")}<br />
                                                        {/* {shippedDate.toISOString()} */}
                                                    </td>
                                                </>
                                            }
                                            {
                                                (tab === 'canceled') &&
                                                <td>
                                                    <span style={{whiteSpace:"nowrap"}}>
                                                        Canceled on: 
                                                        <strong>
                                                            {process.cancelDate}
                                                        </strong>
                                                    </span>
                                                    <br />
                                                    <span style={{whiteSpace:"nowrap"}}>
                                                        Reason: 
                                                        <strong>
                                                            {process.cancelReason}
                                                        </strong>
                                                    </span>
                                                </td>
                                            }
                                            <td className='text-right' >
                                                {
                                                    // (tab !== 'canceled') && (tab !== 'delivered') &&
                                                    // <span className='label bg-red' onClick={() => changeCancel(item.prefixid)} >
                                                    //     Cancel Order
                                                    // </span>
                                                }
                                                {
                                                    consignment !== null &&
                                                    consignment.shipped_via === "Indian Post" &&
                                                    <>
                                                        <span className='label bg-red' onClick={() => downloadLabel(item.id)} >
                                                            Download Label
                                                        </span>
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                                
                                        )
                                    })
                            }
                        </tbody>
                    </table>

                    <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                        
                        {
                                    
                            <div style={{width:"200px", float:"right"}}>
                                <label>Last Page: {lastPage}</label>
                                <div className="input-group input-group-sm">
                                        <input type={"number"} className="form-control" placeholder='e.g 1' value={currentPage} onChange={(e) => setCurrentPage(e.target.value) } />
                                        <span className="input-group-btn">
                                            <button type="button" className="btn btn-primary btn-flat" onClick={() => getOrders(currentPage)}>Go!</button>
                                        </span>
                                </div>
                            </div>
                        }
                    </div>
                    
                </div>
            </div>
            
            </div>
            
        </div>
        {
            cancel !== null &&
            <div className={ "col-md-4"}>
                <form action='' method='POST' onSubmit={(e) => cancelCourse(e)} >
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Cancelation Reason
                            </h3>
                        </div>
                        <div className='box-body'>
                            <textarea className='form-control' value={cancel.msg} rows="5" placeholder='e.g Fake Order' onChange={(e) => changeReason(e.target.value)} ></textarea>
                        </div>
                        <div className='box-footer'>
                            <input type={"submit"} className="btn btn-danger btn-flat btn-block" value={"Cancel Order"} /> 
                        </div>
                    </div>
                </form>
            </div>
        }
        
        {
            bulkAction !== "" &&
            <div className={ "col-md-4"}>
            {
                bulkAction !== "Processed" &&
                    <form action='' method='POST' onSubmit={(e) => bulkMove(e)} >
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    {bulkAction} Reason
                                </h3>
                            </div>
                            <div className='box-body'>
                                <label>Select Reason</label>
                                <select className='form-control' value={bulkActionReason} required onChange={(e) => setBulkActionReason(e.target.value)} >
                                    <option value={""}>Select Reason</option>
                                    {
                                        (bulkAction === "Re-Dispatch") &&
                                        <>
                                            <option value={"Wrong Address"}>Wrong Address</option>
                                            <option value={"Wrong Book Sent"}>Wrong Book Sent</option>
                                            <option value={"Lost In Transit"}>Lost In Transit</option>
                                        </>
                                    }
                                    {
                                        (bulkAction === "Place Again") &&
                                        <>
                                            <option value={"Wrong Address"}>Wrong Address</option>
                                        </>
                                    }
                                    <option value={"Other"}>Other</option>
                                </select>
                            </div>
                            <div className='box-body'>
                                <label>Remarks With Any</label>
                                <textarea className='form-control' value={bulkActionReasonOther} rows="5" placeholder='e.g Fake Order' onChange={(e) => setBulkActionReasonOther(e.target.value)} ></textarea>
                            </div>
                            <div className='box-footer'>
                                <input type={"submit"} className="btn btn-danger btn-flat btn-block" value={bulkAction + " Order"} /> 
                            </div>
                        </div>
                    </form>
            } 
            {
                bulkAction === "Processed" &&
                    <form action='' method='POST' onSubmit={(e) => bulkProcess(e)} >
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Process Order
                                </h3>
                            </div>
                            {/* <div className='box-body'>
                                <label>Select Shipping Type</label>
                                <select className='form-control' value={shiptype} required onChange={(e) => setShipType(e.target.value)} >
                                    <option value={""}>Select Shipping Type</option>
                                    <option value={"DTDC"}>DTDC</option>
                                    <option value={"Logistify"}>Logistify</option>
                                    <option value={"Other"}>Other</option>
                                </select>
                            </div> */}
                            <div className='box-body'>
                                <label>Aye You Sure You Want to Process?</label>
                                <select className='form-control' value={shiptype} required onChange={(e) => setShipType(e.target.value)} >
                                    <option value={""}>Select Yes/No</option>
                                    <option value={"Yes"}>Yes</option>
                                    <option value={"No"}>No</option>
                                </select>
                            </div>
                            {
                                shiptype === "Yes" &&
                                <div className='box-footer'>
                                    <input type={"submit"} className="btn btn-danger btn-flat btn-block" value={"Process Orders"} /> 
                                </div>
                            }
                        </div>
                    </form>
            } 
            {
                bulkSending &&
                <>
                    {
                        bulkSendingCount > 0 &&
                        <div class="alert alert-warning alert-dismissible">
                            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                            <h4><i class="icon fa fa-warning"></i> Processing!</h4>
                            Processing {bulkSendingCount} Orders
                        </div>
                    }

                    {
                        failedList.length > 0 &&
                        <div class="alert alert-danger alert-dismissible">
                            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                            <h4><i class="icon fa fa-warning"></i> Failed Orders!</h4>
                            <ol>
                            {
                                failedList.map((item, index) => {
                                    return(
                                        <li key={"failed" + index}>
                                            <strong>{item.orderid}: </strong>{item.message}<br />
                                        </li>
                                    )
                                })
                            }    
                            </ol>
                        </div>
                    }

                    {
                        successList.length > 0 &&
                        <div class="alert alert-success alert-dismissible">
                            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                            <h4><i class="icon fa fa-warning"></i> Successful Orders!</h4>
                            <ol>
                            {
                                successList.map((item, index) => {
                                    return(
                                        <li key={"success" + index}>
                                            <strong>{item.orderid}: </strong>{item.message}<br />
                                        </li>
                                    )
                                })
                            }    
                            </ol>
                        </div>
                    }
                </>
            }
            </div>
        }
    </>
    }
    
        </>
    )
  }
  return (
    <>
      <Sidebar page={OrdersPages} permission="Orders"/>
    </>
  )
}
